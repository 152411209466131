* {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;

  font-family: 'Montserrat', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.8em;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
}

ul {
  list-style-type: none;
  padding-left: 1em;
}


ul li::before {
  content: "";
  background-image: url('./smallLogo.png'); 
  background-size: 0.8em 0.8em; 
  background-origin: border-box;
  background-position: bottom right;
  background-repeat: no-repeat;
  height: 1em;
  aspect-ratio: 1/1;
  display: inline-block;
  margin-right: 0.5em;        
}

li {
  margin-bottom: 1em;
  padding-left: 2em;
  line-height: 1.8em;
  font-weight: 500;
  padding: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

