.gallery {
    display: grid;
    grid-template-rows: 1fr auto;
    width: 100%;
}

.gallery__main-img-holder {
    display: flex;
    justify-content: center;
    width: 100%;
}

.gallery__main-img {
    width: 90%;
    aspect-ratio: 3/2;
    object-fit: cover;
    border-radius: 10%;
}

.gallery__imgs-holder {
    padding: 1rem 2rem ;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.gallery__img-holder {
    cursor: pointer;
    aspect-ratio: 3/2;
    width: 25%;
    margin: 0 0.5rem;
}

.gallery__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5%;
}

.active {
    border: 2px solid black;
}

.appear {
    animation-name: appear;
    animation-duration: 1s;
}

@keyframes appear {
    from {opacity: 0.6;}
    to {opacity: 1;}
}