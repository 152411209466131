.navbar {
    --color-menu-btn: black;
    --time-appear-menu: 1s;

    display: grid;
    grid-template-columns: 1fr 7fr;

    color: black;

    z-index: 2;

    transition: all 1s ease;

    width: 100%;

    min-height: 10vh;
}

.navbar__logo-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:2em;
    padding-right: 1em;
}

.navbar__logo {
    width: 9em;
}

.navbar__toggle-menu {
    display: none;
    padding: 0;
    background-color: transparent;
    border: none;
    

    height: 100%;
    width: 2rem;

    margin-left: auto;
    margin-right: 2rem;
    
    cursor: pointer;
}

.navbar__toggle-menu-line {
    width: 100%;
    height: 0.4em;
    background-color: var(--color-menu-btn);
    margin-bottom: 0.2em;
    transition: all 1s;
}


.transform-plus-45-degrees {
    transform: translate(0rem, 0.5rem) rotate(45deg);
    background-color: black;
}

.opacity-zero {
    opacity: 0;
}

.transform-minus-45-degrees {
    transform: translate(0rem, -0.5rem) rotate(-45deg);
    background-color: black;
}

.navbar__menu {
    display: flex;
    
    margin-right: 2rem;
    margin-left: auto;

}

.navbar__link {
    display: flex;
    align-items: center;

    color: inherit;

    padding: 1rem 2rem;
    
    text-decoration: none;
}

.navbar__link:hover {
    background-color: rgba(0, 0, 0, 0.2)
}

.visible {
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;
    
    animation-name: appear;
    animation-duration: var(--time-appear-menu);
}

.disappear {
    display: flex !important;
    flex-direction: column;
    justify-content: space-around;

    animation-name: disappear;
    animation-duration: var(--time-appear-menu);
    animation-fill-mode: forwards;
}

@keyframes appear {
    from {transform: translate(100%, 0);}
    to {transform: translate(0, 0);}
}


@keyframes disappear {
    from {transform: translate(0, 0);}
    to {transform: translate(100%, 0);}
}

@media only screen and (max-width: 992px) {

    .navbar {
        grid-template-columns: 1fr 4fr;
        position: sticky;
        top: 0px;
        background-color: #FFFFFF !important;
    }

    .navbar__toggle-menu {
        display: block;
        position:sticky;
        z-index: 4;
    }
    
    .navbar__menu {
        display: none;

        

        grid-column: 1 / -1;

        height: 100vh;
        width: 100%;

        background: linear-gradient(#FFFFFF, #B8E1FF);

        transition: width 0.3s ease;

        position: fixed;

        top: 0%;

        padding-top: 2rem;

        z-index: 3;

        background-color: white;

    }   

    .navbar__link {
        justify-content: center;
    }

    .navbar__logo {
        width: 15em;
    }

    .navbar__logo-holder {
        flex-direction: column;
        gap: 0;
    }
}

