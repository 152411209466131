.slide__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    filter: brightness(0.7);
}

.slide__title-holder {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slide__title {
    width: 70%;
    color: white;
}