.element-holder {
    position: relative;
    z-index: 1;
}

.term {
    font-weight: 700;
    text-decoration: underline;
    font-size: inherit;
    cursor: pointer;
}


.definition {
    position: absolute;
    bottom: 120%;
    left: -5%;
    min-width: fit-content;
    width: 200%;
    max-width:max-content;
    background-color: white;
    overflow: hidden;
    z-index: 1;
}

.definition__close-btn-holder {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.definition__close-btn {
    font-size: 1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.para {
    padding: 0 0.5rem;
    margin: 0;
    font-size: 0.8rem;
}

