.biocon-introduction {
    min-height: 100vh;
    background: linear-gradient(#FFFFFF, #a1eaa1);
}

.biocon-introduction__main-content {
    width: 100%;
    padding-bottom: 2rem;
    color: #1c7c54;
    font-weight: 500;

}

.biocon-introduction__header {
    width: 100%;
}

.biocon-introduction__heading {
    color: #1c7c54;
}

.biocon-introduction__hr{
    border: 1px solid #1c7c54;
}

.biocon-introduction__text {
    padding: 0 2rem;
}

.biocon-introduction__img-holder {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.biocon-introduction__img {
    width: 70%;
    border: 3px solid #1c7c54;
}


.biocon-advantages {
    min-height: 105vh;

    background: linear-gradient(#a2c5ac, #9db5b2);
}

.biocon-advantages__header {
    width: 100%;
}

.biocon-advantages__heading {
    text-align: center;
    color: #226f54;
}

.biocon-advantages__hr {
    border: 1px solid #226f54;
}

.biocon-advantages__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 3rem;
}

.biocon-advantages__text {
    width: 100%;
    color: #226f54;
}

.biocon-advantages__gallery-holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

.biocon-p-and-n-section {
    min-height: 105vh;

    background: linear-gradient(#9EE37D, #82d848);

    padding: 2rem 0;
}

.biocon-p-and-n-section__heading {

    color: #435058;
}

.biocon-p-and-n-section__hr {
    border: 1px solid #435058;
}

.biocon-p-and-n-section__main {
    display: grid;
}

.biocon-p-and-n-section__text {
    padding: 0 2rem;
    color: #435058;

}

.biocon-p-and-n-section__img-holder {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
}

.biocon-p-and-n-section__img {
    width: 70%;
    border: 3px solid #435058;
}

.advantages-biocon-p-and-n-section {
    min-height: 100vh;
    background: linear-gradient(#9593D9, #7C90DB);
    color: #0C1821;
}

.advantages-biocon-p-and-n-section__heading {
    color: #0C1821;;
}

.advantages-biocon-p-and-n-section__hr {
    border: 1px solid #0C1821;;
}

.advantages-biocon-p-and-n-section__main {
    color: #0C1821;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.advantages-biocon-p-and-n-section__text {
    padding: 1rem 2rem;
}

.advantages-biocon-p-and-n-section__gallery-holder {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 992px) {
    .biocon-introduction__img  {
        width: 95%;
    }
    
    .biocon-advantages__main{
        grid-template-columns: 1fr;
        padding: 0;
    }

    .biocon-p-and-n-section__img  {
        width: 95%;
    }

    .advantages-biocon-p-and-n-section__main {
        grid-template-columns: auto;
    }
}